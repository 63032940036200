export default class ScenarioLog {
    id: number;
    dateTime: number;
    scenarioId: number;


    getDate(): Date {
        return new Date(this.dateTime * 1000);
    }


    constructor(props) {
        this.id = props.id;
        this.dateTime = props.dateTime;
        this.scenarioId = props.scenarioId;
    }

    static create() {
        return new ScenarioLog({
            id: 0,
            dateTime: 0,
            scenarioId: 0
        });
    }
}
